<template>
  <div
    style="position: relative; height: calc(100vh - 40px); overflow-y: scroll"
  >
    <el-backtop target=".el-main"></el-backtop>

    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>

    <div class="select">
      <el-select v-model="title" placeholder="请选择要查询的商品">
        <el-option
          v-for="item in optionsName"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px; margin-right: 10px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input v-model="phone" placeholder="请输入要查询的手机号"></el-input>

      <el-button type="primary" @click="searchList">查询</el-button>
      <el-button type="info" @click="reset" style="margin-right: 40px"
        >重置</el-button
      >
    </div>

    <div class="table_con">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{
              Math.max(
                0,
                scope.$index +
                  (currentPage > 0 ? currentPage - 1 : 0) * pageSize +
                  1
              )
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="iconUrl"
          label="商品图片"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.iconUrl"
              :preview-src-list="[scope.row.iconUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="skuPrice"
          label="商品价格"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="goodsNum"
          label="商品数量"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="skuAttr"
          label="商品规格"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="orderIdcopy"
          label="订单编号"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="payType"
          label="实收款"
          align="center"
          width="280"
        >
          <template slot-scope="scope">
            {{
              scope.row.payType == 1
                ? scope.row.amount
                : scope.row.payType == 7
                ? scope.row.amountUsdt
                : scope.row.payType == 8
                ? scope.row.amountToken
                : scope.row.payType == 10
                ? `${scope.row.p95Amount}(95%余额) + ${scope.row.p05Amount}(5%cple积分)`
                : 0
            }}<br />(快递：0.00)
          </template>
        </el-table-column>
        <el-table-column
          prop="createTimeCopy"
          label="下单时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" width="120" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleDeliery(scope.row)"
              >发货</el-button
            >
            <br />
            <el-button
              size="mini"
              type="text"
              @click="handleRefund(scope.row)"
              style="color: #f56c6c; margin-left: 0"
              >退款</el-button
            >
            <br />
            <el-button
              size="mini"
              type="text"
              @click="goDetail(scope.row)"
              style="margin-left: 0"
              >查看详情</el-button
            >

            <el-dialog
              title="发货"
              :visible.sync="dialogFormVisible"
              :append-to-body="true"
            >
              <el-form :model="form">
                <el-form-item label="快递单号" :label-width="formLabelWidth">
                  <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="快递公司" :label-width="formLabelWidth">
                  <el-select v-model="form.region" placeholder="请选择快递公司">
                    <el-option label="顺丰快递" value="顺丰快递"></el-option>
                    <el-option label="京东物流" value="京东物流"></el-option>
                    <el-option label="中通快递" value="中通快递"></el-option>
                    <el-option label="韵达快递" value="韵达快递"></el-option>
                    <el-option label="EMS" value="EMS"></el-option>
                    <el-option label="圆通快递" value="圆通快递"></el-option>
                    <el-option label="申通快递" value="申通快递"></el-option>
                    <el-option label="极兔快递" value="极兔快递"></el-option>
                    <el-option label="德邦快递" value="德邦快递"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleConfirm"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
        :page="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { areaCodeData } from "@/util/areaCodeData";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

import { transformArray } from "./index.js";

export default {
  components: { Breadcrumb, Pagination },
  inject: ["reload"],
  data() {
    return {
      title: "",
      phone: "",
      valueArea: "",
      options: [],
      optionsName: [],
      tableData: [],

      BreadcrumbCon: [
        {
          name: "趣宝商城",
        },
        {
          name: "订单管理",
        },
        {
          name: "待发货订单",
        },
      ], // 面包屑数据

      dialogFormVisible: false,

      expressOrderId: "",
      refundOrderId: "",

      form: {
        name: "",
        region: "",
      },
      formLabelWidth: "120px",

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getListToWaitShipOrderVaitDeliver,
      method: "POST",
      params: JSON.stringify({
        pageNum: localStorage.getItem("orderPayDetailPage") * 1,
        pageSize: localStorage.getItem("orderPayDetailSize") * 1,
        phone: "",
        phoneArea: this.phoneAreaROLE,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          hideLoading();

          this.dataList(res.data.data.list);
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          this.currentPage = localStorage.getItem("orderPayDetailPage");
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);

    const optName = {
      url: reqUrl.getGoodsIdList,
      method: "POST",
      params: JSON.stringify({}),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        } else if (res.data.code == 0) {
          this.optionsName = transformArray(res.data.data);
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
      },
    };
    request(optName);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.getListToWaitShipOrderVaitDeliver,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.phoneAreaROLE,
          goodsId: this.title,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();

            this.dataList(res.data.data.list);
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = localStorage.getItem("orderPayDetailPage");
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    goDetail(row) {
      this.$router.push("/MallOrderDetail/vaitDeliver");
      localStorage.setItem("orderPayDetailRow", JSON.stringify(row));
    },

    handleDeliery(row) {
      this.dialogFormVisible = true;
      this.expressOrderId = row.orderIdcopy;
    },

    handleConfirm() {
      showLoading();
      const opt = {
        url: reqUrl.deliverGood,
        method: "POST",
        params: JSON.stringify({
          orderId: this.expressOrderId,
          expressName: this.form.region,
          expressNum: this.form.name,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            this.dialogFormVisible = false;
            this.refresh();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleRefund(row) {
      this.refundOrderId = row.orderIdcopy;

      this.$confirm("该订单退款后无法恢复，确定要继续退款吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrl.refundOrder,
            method: "POST",
            params: JSON.stringify({
              orderId: this.refundOrderId,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                hideLoading();
                this.dialogFormVisible = false;
                this.$message({
                  type: "success",
                  message: "退款成功!",
                });

                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    handlePageChange(data) {
      localStorage.setItem("orderPayDetailSize", data.pageSize);
      localStorage.setItem("orderPayDetailPage", data.pageIndex);

      showLoading();
      const opt = {
        url: reqUrl.getListToWaitShipOrderVaitDeliver,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.phoneAreaROLE,
          goodsId: this.title,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();

            this.dataList(res.data.data.list);
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    dataList(data) {
      let arrList = [];
      data.map((item) => {
        const { orderId } = item.mallOrderInfo;
        const { createTime } = item.payOrderInfo;
        let obj = {};
        obj = {
          ...item.goodsInfo,
          ...item.mallOrderInfo,
          ...item.payOrderInfo,
          ...item.skuInfo,
          ...item.userInfo,
        };
        obj.orderIdcopy = orderId;
        obj.createTimeCopy = createTime;
        arrList.push(obj);
      });
      this.tableData = arrList;
      arrList.map((item) => {
        if (item.isPaid == false) {
          item.orderStatus = "等待买家付款";
        } else if (item.isPaid == true && item.isShip == false) {
          item.orderStatus = "待发货";
        } else if (
          item.isPaid == true &&
          item.isShip == true &&
          item.isConfirm == false
        ) {
          item.orderStatus = "已发货";
        } else if (
          item.isPaid == true &&
          item.isShip == true &&
          item.isConfirm == true
        ) {
          item.orderStatus = "已签收";
        } else if (item.isCancel == true) {
          item.orderStatus = "已取消";
        }
        item.createTimeCopy = new Date(item.createTimeCopy).toLocaleString();
        item.payTime = new Date(item.payTime).toLocaleString();
      });
    },

    reset() {
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

::v-deep .el-dialog__header,
.el-form-item {
  text-align: left;
}

::v-deep .el-dialog {
  box-shadow: none;
  border-radius: 20px;
}

.el-input {
  width: 200px;
  margin-right: 10px;
}
</style>
