<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>

    <div @click="handleBack">
      <i class="el-icon-arrow-left"></i>
      <span>返回订单列表</span>
    </div>

    <div class="pay_order_status">
      <h3>订单状态：待发货</h3>
      <el-steps :active="2" finish-status="success" align-center>
        <el-step title="买家下单"></el-step>
        <el-step title="买家付款"></el-step>
        <el-step title="发货"></el-step>
        <el-step title="买家确认收货"></el-step>
      </el-steps>
    </div>

    <p class="pay_order_msg_p">订单信息</p>
    <div class="pay_order_msg">
      <div class="pay_order_trading_msg">
        <h5>交易信息</h5>
        <p>
          订单编号：<span>{{ orderDataRow.orderIdcopy }}</span>
        </p>
        <p>
          创建时间：<span>{{ orderDataRow.createTime }}</span>
        </p>
        <p>
          付款时间：<span>{{ orderDataRow.payTime }}</span>
        </p>
      </div>
      <div class="pay_order_trading_msg">
        <h5>买家信息</h5>
        <p>
          昵称：<span>{{ orderDataRes.realName }}</span>
        </p>
        <p>
          账号：<span>{{ orderDataRes.phone }}</span>
        </p>
        <p>
          支付方式：<span>{{ orderDataRow.payType }}</span>
        </p>
        <el-button
          type="text"
          style="color: #67c23a"
          v-clipboard:copy="messageBuy"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          复制买家信息
        </el-button>
      </div>
      <div class="pay_order_trading_msg">
        <h5>物流信息</h5>
        <p>
          收货人：<span>{{ orderDataRes.contacts }}</span>
        </p>
        <p>
          区号：<span>{{ areaCode }}</span>
        </p>
        <p>
          收货人电话：<span>{{ orderDataRes.number }}</span>
        </p>
        <p>
          收货地址：<span
            >{{ orderDataRes.province }}{{ orderDataRes.city
            }}{{ orderDataRes.district }}{{ orderDataRes.address }}</span
          >
        </p>

        <el-button
          type="text"
          style="color: #67c23a"
          v-clipboard:copy="messageExpress"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          复制物流信息
        </el-button>
      </div>
    </div>

    <!-- <el-dialog title="物流信息" :visible.sync="dialogVisible" width="80%">
      <div>
        <div class="pay_dialog_msg">
          <p>交易订单编号：321112345675433</p>
          <p>支付时间：2022-1-1 10:00</p>
          <p>
            收货地址：张三，13011112222，北京市某某区某某街道某某小区某某单元101，100000
          </p>
        </div>
        <el-divider></el-divider>
        <div class="pay_dialog_msg_b">
          <div class="pay_dialog_parcel_msg">
            <h3>包裹信息</h3>
            <p>物流单号：78612345678123</p>
            <p>物流公司：顺丰速运</p>
            <p>发货时间：2022-12-1 10:11:30</p>
            <p style="display: flex">
              包裹商品：<img src="@/assets/box.png" />
            </p>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="pay_dialog_parcel_msg" style="width: 500px">
            <h3>物流动态</h3>
            <el-timeline :reverse="reverse">
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :timestamp="activity.timestamp"
              >
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->

    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column prop="iconUrl" label="商品图片">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.iconUrl"
              :preview-src-list="[scope.row.iconUrl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column prop="skuPrice" label="商品价格"> </el-table-column>
        <el-table-column prop="goodsNum" label="商品数量"> </el-table-column>
        <el-table-column prop="skuAttr" label="商品规格"> </el-table-column>
        <el-table-column prop="payType" label="实收款" align="center">
          <template slot-scope="scope">
            ¥{{
              scope.row.payType == 1
                ? scope.row.amount
                : scope.row.payType == 7
                ? scope.row.amountUsdt
                : scope.row.payType == 8
                ? scope.row.amountToken
                : 0
            }}<br />(快递：0.00)
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      dialogVisible: false,
      reverse: true,

      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],

      areaCode: "",
      messageBuy: "",
      messageExpress: "",
      orderDataRow: [],
      orderDataRes: [],

      tableData: [],

      BreadcrumbCon: [
        {
          name: "趣宝商城",
        },
        {
          name: "商城订单",
        },
        {
          name: "待发货订单",
        },
        {
          name: "查看详情",
        },
      ], // 面包屑数据
    };
  },

  mounted() {
    this.orderDataRow = JSON.parse(localStorage.getItem("orderPayDetailRow"));

    if (this.orderDataRow.payType == 1) {
      this.orderDataRow.payType = "余额账户";
    } else if (this.orderDataRow.payType == 2) {
      this.orderDataRow.payType = "CPLE积分账户";
    } else if (this.orderDataRow.payType == 3) {
      this.orderDataRow.payType = "支付宝账户";
    } else if (this.orderDataRow.payType == 4) {
      this.orderDataRow.payType = "微信账户";
    } else if (this.orderDataRow.payType == 5) {
      this.orderDataRow.payType = "京东账户";
    } else if (this.orderDataRow.payType == 6) {
      this.orderDataRow.payType = "转出额度";
    } else if (this.orderDataRow.payType == 7) {
      this.orderDataRow.payType = "USDT";
    } else if (this.orderDataRow.payType == 8) {
      this.orderDataRow.payType = "链上CPLE TOKEN";
    }
    this.tableData.push(JSON.parse(localStorage.getItem("orderPayDetailRow")));
    let orderid = this.orderDataRow.orderIdcopy;

    showLoading();
    const opt = {
      url: reqUrl.getMallAddressInfo,
      method: "POST",
      params: JSON.stringify({
        orderId: orderid,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          hideLoading();
          this.orderDataRes = res.data.data;
          this.areaCode = res.data.data.phoneArea;

          this.messageBuy = `买家昵称：${this.orderDataRes.realName} 买家账号：${this.orderDataRes.phone} 支付方式：${this.orderDataRow.payType}`;

          this.messageExpress = `${this.orderDataRes.contacts} ${this.orderDataRes.phoneArea} ${this.orderDataRes.number} ${this.orderDataRes.province}${this.orderDataRes.city}${this.orderDataRes.district}${this.orderDataRes.address}`;
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    handleLogisticsMsg() {
      this.dialogVisible = true;
    },

    handleBack() {
      this.$router.push({
        path: "/MallPayListVaitDeliver",
      });
      localStorage.removeItem("orderPayDetailRow");
    },

    onCopy() {
      this.$message.success("复制地址成功");
    },
    onError() {
      this.$message.error("复制地址失败");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../index.scss";
</style>
